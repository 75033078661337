header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 13px auto;
    max-width: 1165px;
    width: 100%;
    .logo {
        // transition: .3s;
        animation: logoAnimation 2s infinite;
        @keyframes logoAnimation {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(.9);
            }
            100% {
                transform: scale(1);
            }
        }
        @media( max-width: 400px ) {
            svg {
                max-width: 180px;
            }
        }
        img {
            width: 68.5px;
            height: 85px;
        }
    }
    nav {
        display: flex;
        align-items: center;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            @media( max-width: 550px ) {
                flex-direction: column;
            }
            li {
                list-style: none;
                padding-left: 18px;
                position: relative;
                overflow: hidden;
                &:hover {
                    a {
                        color: $red;
                        text-shadow: 1px 0 0 $red;
                        &:after,
                        &:before {
                            transform: translateX(0);
                        }
                    }
                }
                &:not(&:last-of-type) {
                    margin-right: 28px;
                    @media( max-width: 550px ) {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    background-color: $red;
                    border-radius: 50%;
                }
                // &:after {
                //     content: "";
                //     position: absolute;
                //     bottom: 0px;
                //     left: 0;
                //     width: 100%;
                //     height: 1px;
                //     transform: translateX(-100%);
                //     background-color: $blue;
                //     transition: .3s;
                // }
                a {
                    display: block;
                    text-decoration: none;
                    color: $blue;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    transition: .3s;
                    padding: 2px 0;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 1px;
                        transform: translateX(100%);
                        background-color: $blue;
                        transition: .3s;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        transform: translateX(-100%);
                        background-color: $blue;
                        transition: .3s;
                    }
                    @media( max-width: 550px ) {
                        font-size: 16px;
                    }
                    @media( max-width: 400px ) {
                        font-size: 15px;
                    }
                }
            }
        }
        .lang-switcher {
            margin-left: 20px;
            p {
                margin: 0;
                font-weight: bold;
                color: #003482;
                cursor: pointer;
            }
        }
    }
}