.banner {
    margin-bottom: 30px;
    .container {
        position: relative;
        .bg-wrapper {
            img, video {
                object-fit: cover;
                max-height: 550px;
                height: 100%;
                max-width: 1410px;
                width: 100%;
                border-radius: 10px;
                @media( max-width: 850px ) {
                    height: 300px;
                }
            }
        }
        .text-wrapper {
            position: absolute;
            bottom: 65px;
            left: 120px;
            max-width: 570px;
            width: 100%;
            min-height: 265px;
            border-radius: 10px;
            opacity: 0.9;
            // background: linear-gradient(135deg, #37CDE2 -14.38%, #003482 79.04%);
            background: linear-gradient(135deg, #E23737 -14.38%, #A78920 79.04%);
            backdrop-filter: blur(50px);
            display: flex;
            align-items: center;
            padding: 76px 64px 70px 45px;
            @media( max-width: 1200px ) {
                padding: 30px 50px;
                max-width: 450px;
            }
            @media( max-width: 1024px ) {
                padding: 30px 20px;
                max-width: 350px;
                min-height: initial;
                left: 30px;
                bottom: 30px;
            }
            @media( max-width: 500px ) {
                padding: 20px 25px;
                max-width: calc(100% - 10px);
                left: 5px;
                right: 5px;
                bottom: 10px;
            }
            h1,h2 {
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase; 
                margin: 0;
                @media( max-width: 1200px ) {
                    font-size: 32px;
                }
                @media( max-width: 1024px ) {
                    font-size: 28px;
                }
                @media( max-width: 500px ) {
                    font-size: 22px;
                }
            }
        }
    }
}