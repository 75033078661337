.form {
    // max-width: 930px;
    // width: 100%;
    // margin: 0 auto;
    margin-top: 50px;
    .headers {
        text-align: center;
        margin-bottom: 30px;
        .number-wrapper {
            max-width: 300px;
            margin: 15px auto 0 auto;
        }
        h3 {
            color: $blue;
            font-size: 24px;
            margin: 0;
        }
        // p {
        //     color: $gray;
        //     font-size: 24px;
        //     margin: 0;
        // }
    }
    .validate-emails {
        text-align: center;
    }
    .emails-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 -15px 0 -15px;
        @media( max-width: 850px ) {
            flex-wrap: wrap;
            margin: 0;
        }
        .email-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: calc(33.3% - 30px);
            width: 100%;
            margin: 0 15px;
            border: 1px solid #b3bfd2;
            border-radius: 10px;
            padding: 30px 16px 24px 16px;
            min-height: 245px;
            position: relative;
            background-color: #ffffff;
            text-align: center;
            cursor: pointer;
            @media( max-width: 850px ) {
                flex-wrap: wrap;
                max-width: 100%;
                margin: 0 0 15px 0;
            }
            &.error {
                border-color: $error;
            }
            &.active {
                border: 4px solid rgb(33, 214, 33);
                .btn-wrapper {
                    .btn {
                        border: 2px solid rgb(33, 214, 33);
                        .arrow {
                            background-color: rgb(33, 214, 33);
                        }
                    }
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            h3 {
                color: $blue;
                font-size: 24px;
                margin: 0;
                margin-bottom: 20px;
                &:first-of-type {
                    min-height: 84px;
                    @media( max-width: 1024px ) {
                        min-height: 92px;
                    }
                    @media( max-width: 850px ) {
                        min-height: initial;
                    }
                }
                @media( max-width: 1024px ) {
                    font-size: 20px;
                }
            }
            p {
                color: $gray;
                font-size: 20px;
                margin: 0;
                margin-bottom: 20px;
                @media( max-width: 1024px ) {
                    font-size: 18px;
                }
                strong {
                    display: block;
                }
            }
            .btn-wrapper {
                .btn-wrap {
                    .btn {
                        margin: 0 auto;
                        @media( max-width: 1024px ) {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }
    form {
        max-width: 930px;
        width: 100%;
        margin: 40px auto 0 auto;
        .group {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 23px 0;
            // padding-bottom: 20px;
            border-bottom: 1px solid $border;
            &:nth-of-type(4) {
                margin-bottom: 0;
            }
            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
                position: relative;
                margin-top: 0;
                padding-top: 23px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -1000px;
                    width: 600vw;
                    height: 100%;
                    background-color: #FFFFFF;
                    z-index: -2;
                }
            }
            &:first-of-type {
                margin-top: 0;
            }
            .fields {
                // display: flex;
                // flex-wrap: wrap;
                // margin: 0 -15px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr; 
                grid-template-rows: 1fr; 
                grid-template-areas: 
                ". . .";
                gap: 30px 15px;
                max-width: 100%;
                margin-bottom: 20px;
                @media( max-width: 800px ) {
                    grid-template-columns: 1fr; 
                    grid-template-areas: ".";
                    gap: 20px 15px;
                }
                .row-item {
                    position: relative;
                    width: 100%;
                    .remove-item {
                        position: absolute;
                        top: calc(50% - 12px);
                        right: -25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @media( max-width: 800px ) {
                            right: -17px;
                            top: calc(50% - 7.5px);
                            svg {
                                width: 15px!important;
                                height: 15px!important;
                            }
                        }
                        svg {
                            transform: rotate(45deg);
                            path {
                                fill: $red;
                            }
                        }
                    }
                    &.dates {
                        display: flex;
                        .input-date-picker-wrap {
                            &:first-of-type {
                                // margin-right: 15px;
                                .react-datepicker-input {
                                    border-right: none !important;
                                    border-radius: 10px 0 0 10px !important;
                                }
                            }
                            &:nth-of-type(2) {
                                // margin-left: 15px;
                                .react-datepicker-input {
                                    border-left: none !important;
                                    border-radius: 0 10px 10px 0 !important;
                                }
                            }
                            label {
                                position: absolute;
                                top: -19px;
                                @media(max-width: 800px ) {
                                    top: -15px;
                                }
                            }
                        }
                    }
                    &.date {
                       display: flex;
                       label {
                            position: absolute;
                            top: -19px;
                            @media(max-width: 800px ) {
                                top: -15px;
                            }
                        } 
                    }
                }
            }
            .row-big-item {
                margin-bottom: 20px;
                .wrapper-radio-btns {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 9px 92px 10px 20px;
                    max-width: 615px;
                    width: 100%;
                    min-height: 41px;
                    border-radius: 10px;
                    border: 1px solid var(--Granat, #003482);
                    background: #FFF;
                    @media( max-width: 550px ) {
                        padding-right: 20px;
                    }
                    label {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        // font-family: "Proxima Nova";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        p {
                            margin: 0;
                            margin-right: 5px;
                            color: #003482;
                        }
                    }
                    .radio-wrapper {
                        &:first-of-type {
                            margin: 0 20px;
                        }
                    }
                }
            }
            .finish {
                margin-bottom: 11px;
            }
            .row-item {
                &.hidden-item {
                    margin: 0;
                    visibility: hidden;
                }
                .btn-wrap {
                    // margin: 0 -15px;
                    margin-bottom: 20px;
                    .btn {
                        // margin: 0 15px;
                        max-width: 300px;
                        width: 100%;
                    }
                }
                &.submit {
                    .btn-wrap {
                        margin-top: 7px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}