.editor {
    margin: 10px auto 30px auto;
    max-width: 930px;
    * {
        color: #9D9D9D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    ul {
        margin: 0;
        padding: 0;
        li { 
            list-style: none;
            padding-left: 20px;
            position: relative;
            overflow: hidden;
            margin-bottom: 19px;
            @media( max-width: 1200px ) {
                margin-bottom: 10px;
            }
            @media( max-width: 1024px ) {
                padding-left: 14px;
                margin-bottom: 5px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                transform: translateY(50%);
                width: 8px;
                height: 8px;
                background-color: #CF4E40;
                border-radius: 50%;
                @media( max-width: 1024px ) {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }
    ol {
        position: relative;
        counter-reset: my-counter;
        padding-left: 0;
        margin: 0;
        li {
            counter-increment: my-counter;
            position: relative;
            list-style: none;
            margin-bottom: 15px;
            font-size: 1.2rem;
            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: counter(my-counter)".";
                color: #CF4E40;
                padding-right: 7px;
                font-weight: bold;
            }
        }
    }
}