.title {
    margin: 0 auto;
    &.with-line {
        padding-bottom: 19px;
        border-bottom: 1px solid $border;
    }
    .container {
        max-width: 100%;
        margin: 0 auto;
        .title-wrapper {
            text-align: center;
            h2 {
                color: #CF4E40;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                margin: 0;
                @media( max-width: 1024px ) {
                    font-size: 28px;
                }
                @media( max-width: 500px ) {
                    font-size: 24px;
                }
                &.bold {
                    font-weight: 700;
                }
                &.blue {
                    color: $blue;
                }
            }
        }
    }
}