body, html {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: "Myriad Pro", sans-serif;
}

::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background: $white; 
}
::-webkit-scrollbar-thumb {
    background: $gray; 
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: $gray; 
}
::-moz-selection {
    background: $gray; 
}
  
::selection {
    color: $white;
    background: $gray; 
}

.layout {
    display: flex;
    flex-direction: column;
    max-width: 1470px;
    padding: 0 30px;
    margin: 0 auto;
    @media( max-width: 500px ) {
        padding: 0 20px;
    }
}
#root {
    overflow: hidden;
    max-width: 100vw;
}

section {
    display: block;
}

.hidden {
    display: none !important;
    margin-bottom: 0 !important;
}

.mobile-border {
    display: none;
    @media( max-width: 800px ) {
        display: block;
        border-bottom: 2px solid $border;
    }
}

.hidden-item {
    @media( max-width: 800px ) {
        display: none;
    }
}

.bg-sections {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -300px;
        width: 200vw;
        height: 100%;
        background-color: #F3F0E5;
        z-index: -2;
    }
}